import React from 'react'
import Helmet from 'react-helmet'

const Component: React.FC = () => {
  return (
    <main id="MainZone">
      <Helmet></Helmet>
    </main>
  )
}

export default Component
